import React, { useCallback } from 'react'
import Timer from '../Timer'
import useDeviceType from '../../utils/useDeviceType'

import "./styles.css"
import WordsListWords from '../WordsListWords'
import ProfileModal from "../ProfileModal";
type HeaderProps = {
    wordsList: string[],
    currentWord: string,
}

const HeaderWords: React.FC<HeaderProps> = ({ wordsList, currentWord }) => {
    const deviceType = useDeviceType()

    return (
        <div className='header-container'>
            <div className="standard-width">
                {deviceType != 'mobile' &&
                    <a href="/">
                        <img src='https://i.imgur.com/pXaL6J7.png' alt="Logo"/>
                    </a>}
            </div>
            {wordsList && currentWord && <WordsListWords wordsList={wordsList} currentWord={currentWord}/>}
            <div className="standard-width">
                {deviceType === 'mobile' && <ProfileModal />}
                {/* {deviceType != 'mobile' && 
                    // <Timer />
                    <></>
                } */}
            </div>
        </div>)
}

export default HeaderWords;
