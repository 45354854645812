import React from 'react';
import './styles.css';

const Input = ({type, name, handleOnChange, value, label, handleKeyDown, isError =false, isRequired=false}) => {


  return (
    <div className={`input-content ${isError && 'input-error'}`}>
        {label &&
        <label className='label-element' htmlFor={`input-${name}`}>
          {label}
            {isRequired && <span className="required">*</span>}

        </label>}
        <input
            className='input-element'
            id={`input-${name}`}
            type={type}
            name={name}
            onChange={e => handleOnChange(e)}
            value={value}
            onKeyDown={handleKeyDown}
        />
    </div>
  );
};

export default Input;