import React, {useEffect, useState, useRef} from "react";
import alphabetDetectionProcess from "./hooks/alphabetDetectionProcess";
import './styles.css';
import Header from "../components/Header";
import normalizeWord from "../utils/normalizeWords";
import {fetchWords} from "../utils/fetchWords";
import Countdown from "../components/Countdown";
import EndView from "../views/EndView";
import {fetchVersion} from "../api/api";
import { useUpdateProgress } from "../utils/effects-utils/progressEffects";
import { FRAMES_LENGTH_FOR_PREDICT_ALPHABET } from "../../constants";
import Loader from "../components/Loader";
import ErrorModal from "../components/Modal";
import useDeviceType from "../utils/useDeviceType.ts";


function GameAlphabet() {
    const isModalOpenRef = useRef(false);
    const [showLandmarks, setShowLandmarks] = useState(window.innerWidth >= 1280);
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
    const selectedLetterRef = useRef(selectedLetter);
    const currentFramesLengthForPredict = useRef(FRAMES_LENGTH_FOR_PREDICT_ALPHABET);
    useEffect(() => {
        selectedLetterRef.current = selectedLetter;
    }, [selectedLetter]);
    const [isPlaying, setIsPlaying] = useState(false);
    const isPlayingRef = useRef(isPlaying);
    useEffect(() => {
        console.log("isPlaying state updated:", isPlaying);
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);
    const [showCountdown, setShowCountdown] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [ wordsList, setWordsList] = useState<string[]>([])
    const [ currentIndex, setCurrentIndex ] = useState<number>(0)
    const [ currentLetter, setCurrentLetter ] = useState<string | null>(null)
    const [ endGame, setEndGame ] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState({open: false, message: '', severity: ''});
    const [changeCurrentWord ,setChangeCurrentWord] = useState<boolean>(false)
    const [currentLetterIndex, setCurrentLetterIndex] = useState<number>(0)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [progress, setProgress] = useState(0);
    const deviceType = useDeviceType()

    const [errorModal, setErrorModal] = useState<boolean>(false);

    const handleOpenModal = () => {
        setErrorModal(true);
    };

    const handleCloseModal = () => {
        setErrorModal(false);
    };

    //check state managment when camera is loading or not
    const [isLoadingLandmarks, setIsLoadingLandmarks] = useState<boolean>(false);
    const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false)

    useEffect(() => {
        async function callFetchVersion() {
            const isLoading = await fetchVersion();
            setLoading(isLoading);
        }
        callFetchVersion();

        fetchWords(setIsLoadingApi).then( words => {
            setWordsList(words)
        })

        setCurrentIndex(0)
    }, []);

    const {
        videoElement,
        maxVideoWidth,
        maxVideoHeight,
        canvasEl,
        getProgress,
        resetProgress,
    } = alphabetDetectionProcess({
        isDialogOpen: isModalOpenRef,
        selectedLetterRef,
        showLandmarks,
        setSnackbar: setSnackbarOpen,
        isPlayingRef,
        setIsPlaying,
        setShowCountdown,
        setCurrentLetter,
        setChangeCurrentWord,
        setCurrentLetterIndex,
        setIsLoading: setIsLoadingLandmarks,
        setErrorModal
    });

    useUpdateProgress(getProgress, setProgress);

    const [selectedWord, setSelectedWord] = useState<string>('');


    useEffect(() => {
        const word = wordsList[0]
        const normalizedWord = word && normalizeWord(word);
        setSelectedWord(normalizedWord)
    },[wordsList])

    useEffect(() => {
        if(selectedWord) {
            setCurrentLetter(Array.from(selectedWord)[0])
        }
    }, [selectedWord])

    useEffect(() => {
        if(currentIndex < 5){
            const word = wordsList[currentIndex]
            const normalizedWord = word && normalizeWord(word);
            setSelectedWord(normalizedWord)
        } else {
            setSnackbarOpen({
                open: true,
                message: 'Ha finalizado el juego',
                severity: 'finish-game'
            })
            setShowConfetti(true)
            setEndGame(true)
        }
        
    }, [currentIndex])

    useEffect (() => {
        if(changeCurrentWord) {
            setCurrentIndex(currentIndex + 1);
        }
    }, [changeCurrentWord])

    useEffect(() => {
        setSelectedLetter(selectedWord as string)
        setCurrentLetterIndex(0)
        resetProgress();
        setChangeCurrentWord(false)
    }, [selectedWord])

    return (
        <div className="container">
            <Header wordsList={wordsList} currentWord={selectedWord} currentLetterIndex={currentLetterIndex}/>
            <video 
                className="video"
                playsInline
                autoPlay
                ref={videoElement}
            />
            {!endGame && <canvas className="video" ref={canvasEl} width={maxVideoWidth} height={maxVideoHeight}/>}
            {endGame && 
                <EndView 
                    redirectTo={'/deletrear'}
                    text={<>Felicitaciones <span>deletreaste las 5 palabras</span></>}
                />
            }
            {showCountdown && (
                <Countdown 
                    isPlaying={isPlaying}
                    setShowCountdown={setShowCountdown}
                    setIsPlaying={setIsPlaying}
                    snackbarOpen={snackbarOpen}
                    currentWord={selectedWord}
                    currentIndex={currentIndex}/>
            )}
            { currentLetter &&
                <div className='sign'> 
                    <img
                        src={`/signs/${currentLetter}.jpeg`}
                        alt={`${currentLetter}`}
                        style={{
                            maxHeight: deviceType === 'mobile' ? '60vh' : '40vh',
                            maxWidth: deviceType === 'mobile' ? '35vw' : '25vw',
                        }}
                    />
                </div>
            }
            <div className="progress-container"
                 style={{position: 'absolute', bottom: 0, width: '100%', zIndex: 3, height: '0.6em'}}>
                <div className="progress-bar"
                     style={{width: `${(progress / currentFramesLengthForPredict.current) * 100}%`}}></div>
            </div>
            {errorModal && <ErrorModal open={errorModal} handleClose={handleCloseModal} />}
            {
                !errorModal && (isLoadingLandmarks || isLoadingApi )&& <Loader />
            }
        </div>
    );
}

export default GameAlphabet;
