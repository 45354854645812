import React, { useCallback } from 'react'
import Timer from '../Timer'
import WordsList from '../WordsList'
import useDeviceType from '../../utils/useDeviceType'

import "./styles.css"
import ProfileModal from "../ProfileModal";
type HeaderProps = {
    wordsList: string[],
    currentWord: string,
    currentLetterIndex: number
}

const Header: React.FC<HeaderProps> = ({ wordsList, currentWord, currentLetterIndex }) => {
    const deviceType = useDeviceType()

    return (
        <div className='header-container'>
            <div className="standard-width">
                {deviceType != 'mobile' &&
                    <a href="/">
                        <img src='https://i.imgur.com/pXaL6J7.png' alt="Logo"/>
                    </a>}
            </div>
            {wordsList && currentWord && <WordsList wordsList={wordsList} currentWord={currentWord} currentLetterIndex={currentLetterIndex}/>}
           <div className="standard-width">
               {deviceType === 'mobile' && <ProfileModal />}
               {/* {deviceType != 'mobile' &&
                    // <Timer />
                    <></>
                } */}
            </div>
        </div>)
}

export default Header;
